<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 360 400"
    style="margin: 20px; enable-background: new 0 0 360 400"
    xml:space="preserve"
    @click="toggleMap"
  >
    <!-- Outer ring -->
    <path
      id="outer"
      class="st0"
      d="M69.6,48L180,12.1L290.4,48l68.3,93.9V258l-68.3,94L180,387.9L69.6,352L1.3,258.1V141.9L69.6,48z"
    />

    <!-- Elevators -->
    <path id="elevators" class="st0" d="M1.1,180.6h45.4v39.6H1.1" />

    <!-- Stage -->
    <path
      class="st1"
      d="M140.6,145.8l39.3-12.8l39.3,12.8l24.3,33.4v41.3l-24.3,33.4l-39.3,12.8l-39.3-12.8l-24.3-33.4
	v-41.3L140.6,145.8z"
    />

    <!-- Products -->

    <!-- 0. Parts and services -->
    <NuxtLink
      data-bs-toggle="tooltip"
      :title="content.map[0].text"
      :to="
        content.map[0].link.cached_url.indexOf('/') === 0
          ? content.map[0].link.cached_url
          : `/${content.map[0].link.cached_url}`
      "
    >
      <rect
        tabindex="0"
        :class="{ active: current === 0 }"
        x="101.1"
        y="147.8"
        transform="matrix(0.5844 -0.8114 0.8114 0.5844 -79.395 156.8497)"
        class="st1"
        width="24.6"
        height="16.3"
      />
    </NuxtLink>

    <!-- 1. Marketing -->
    <NuxtLink
      :to="
        content.map[1].link.cached_url.indexOf('/') === 0
          ? content.map[1].link.cached_url
          : `/${content.map[1].link.cached_url}`
      "
    >
      <rect
        data-bs-toggle="tooltip"
        :title="content.map[1].text"
        :class="{ active: current === 1 }"
        x="18.2"
        y="248.8"
        transform="matrix(0.8114 -0.5844 0.5844 0.8114 -147.6246 64.6171)"
        class="st1"
        width="16.3"
        height="24.6"
      />
    </NuxtLink>

    <!-- 2. Training hub -->
    <NuxtLink
      :to="
        content.map[2].link.cached_url.indexOf('/') === 0
          ? content.map[2].link.cached_url
          : `/${content.map[2].link.cached_url}`
      "
    >
      <rect
        data-bs-toggle="tooltip"
        :title="content.map[2].text"
        :class="{ active: current === 2 }"
        x="41.2"
        y="280.5"
        transform="matrix(0.8114 -0.5844 0.5844 0.8114 -161.8223 84.05)"
        class="st1"
        width="16.3"
        height="24.6"
      />
    </NuxtLink>

    <!-- 3. Gallery -->
    <NuxtLink
      :to="
        content.map[3].link.cached_url.indexOf('/') === 0
          ? content.map[3].link.cached_url
          : `/${content.map[3].link.cached_url}`
      "
    >
      <rect
        data-bs-toggle="tooltip"
        :title="content.map[3].text"
        :class="{ active: current === 3 }"
        x="8.9"
        y="188"
        class="st1"
        width="16.3"
        height="24.6"
      />
    </NuxtLink>

    <!-- 4. Media -->
    <NuxtLink
      :to="
        content.map[4].link.cached_url.indexOf('/') === 0
          ? content.map[4].link.cached_url
          : `/${content.map[4].link.cached_url}`
      "
    >
      <rect
        data-bs-toggle="tooltip"
        :title="content.map[4].text"
        :class="{ active: current === 4 }"
        x="31.3"
        y="103.2"
        transform="matrix(0.5844 -0.8114 0.8114 0.5844 -72.2523 81.6583)"
        class="st1"
        width="24.6"
        height="16.3"
      />
    </NuxtLink>

    <!-- 5. Promo shop -->
    <NuxtLink
      :to="
        content.map[5].link.cached_url.indexOf('/') === 0
          ? content.map[5].link.cached_url
          : `/${content.map[5].link.cached_url}`
      "
    >
      <rect
        data-bs-toggle="tooltip"
        :title="content.map[5].text"
        :class="{ active: current === 5 }"
        x="106"
        y="232.9"
        transform="matrix(0.8114 -0.5844 0.5844 0.8114 -121.7938 112.9376)"
        class="st1"
        width="16.3"
        height="24.6"
      />
    </NuxtLink>

    <!-- 6. Nordtrack™ I908 -->
    <NuxtLink
      :to="
        content.map[6].link.cached_url.indexOf('/') === 0
          ? content.map[6].link.cached_url
          : `/${content.map[6].link.cached_url}`
      "
    >
      <circle
        data-bs-toggle="tooltip"
        :title="content.map[6].text"
        :class="{ active: current === 6 }"
        class="st1"
        cx="227.7"
        cy="62.3"
        r="15.2"
      />
    </NuxtLink>

    <!-- 7. Nordtrack™ I908S -->
    <NuxtLink
      :to="
        content.map[7].link.cached_url.indexOf('/') === 0
          ? content.map[7].link.cached_url
          : `/${content.map[7].link.cached_url}`
      "
    >
      <circle
        data-bs-toggle="tooltip"
        :title="content.map[7].text"
        :class="{ active: current === 7 }"
        class="st1"
        cx="266"
        cy="79.1"
        r="17.9"
      />
    </NuxtLink>

    <!-- 8. Nordtrack™ I1011S -->
    <NuxtLink
      :to="
        content.map[8].link.cached_url.indexOf('/') === 0
          ? content.map[8].link.cached_url
          : `/${content.map[8].link.cached_url}`
      "
    >
      <circle
        data-bs-toggle="tooltip"
        :title="content.map[8].text"
        :class="{ active: current === 8 }"
        class="st1"
        cx="298.6"
        cy="115"
        r="21.3"
      />
    </NuxtLink>

    <!-- 9. Nordtrack™ J90 -->
    <NuxtLink
      :to="
        content.map[9].link.cached_url.indexOf('/') === 0
          ? content.map[9].link.cached_url
          : `/${content.map[9].link.cached_url}`
      "
    >
      <circle
        data-bs-toggle="tooltip"
        :title="content.map[9].text"
        :class="{ active: current === 9 }"
        class="st1"
        cx="190.2"
        cy="49.1"
        r="15.5"
      />
    </NuxtLink>

    <!-- 10. Nordtrack™ J127 -->
    <NuxtLink
      :to="
        content.map[10].link.cached_url.indexOf('/') === 0
          ? content.map[10].link.cached_url
          : `/${content.map[10].link.cached_url}`
      "
    >
      <circle
        data-bs-toggle="tooltip"
        :title="content.map[10].text"
        :class="{ active: current === 10 }"
        class="st1"
        cx="145.9"
        cy="51.4"
        r="20.5"
      />
    </NuxtLink>

    <!-- 11. Nordtrack™ S2.11 -->
    <NuxtLink
      :to="
        content.map[11].link.cached_url.indexOf('/') === 0
          ? content.map[11].link.cached_url
          : `/${content.map[11].link.cached_url}`
      "
    >
      <circle
        data-bs-toggle="tooltip"
        :title="content.map[11].text"
        :class="{ active: current === 11 }"
        class="st1"
        cx="327.3"
        cy="205.5"
        r="23"
      />
    </NuxtLink>

    <!-- 12. Nordtrack™ S2.5 -->
    <NuxtLink
      :to="
        content.map[12].link.cached_url.indexOf('/') === 0
          ? content.map[12].link.cached_url
          : `/${content.map[12].link.cached_url}`
      "
    >
      <circle
        data-bs-toggle="tooltip"
        :title="content.map[12].text"
        :class="{ active: current === 12 }"
        class="st1"
        cx="319.4"
        cy="158.7"
        r="17.7"
      />
    </NuxtLink>

    <!-- 13. Nordtrack™ S3.7 -->
    <NuxtLink
      :to="
        content.map[13].link.cached_url.indexOf('/') === 0
          ? content.map[13].link.cached_url
          : `/${content.map[13].link.cached_url}`
      "
    >
      <circle
        data-bs-toggle="tooltip"
        :title="content.map[13].text"
        :class="{ active: current === 13 }"
        class="st1"
        cx="313.8"
        cy="255"
        r="22.7"
      />
    </NuxtLink>

    <!-- 14. Nordtrack™ S3.9 -->
    <NuxtLink
      :to="
        content.map[14].link.cached_url.indexOf('/') === 0
          ? content.map[14].link.cached_url
          : `/${content.map[14].link.cached_url}`
      "
    >
      <circle
        data-bs-toggle="tooltip"
        :title="content.map[14].text"
        :class="{ active: current === 14 }"
        class="st1"
        cx="236.9"
        cy="330.6"
        r="24.2"
      />
    </NuxtLink>

    <!-- 15. Nordtrack™ S4.7 -->
    <NuxtLink
      :to="
        content.map[15].link.cached_url.indexOf('/') === 0
          ? content.map[15].link.cached_url
          : `/${content.map[15].link.cached_url}`
      "
    >
      <circle
        data-bs-toggle="tooltip"
        :title="content.map[15].text"
        :class="{ active: current === 15 }"
        class="st1"
        cx="282.9"
        cy="299.7"
        r="21.9"
      />
    </NuxtLink>

    <!-- 16. Nordtrack™ S4.9 -->
    <NuxtLink
      :to="
        content.map[16].link.cached_url.indexOf('/') === 0
          ? content.map[16].link.cached_url
          : `/${content.map[16].link.cached_url}`
      "
    >
      <circle
        data-bs-toggle="tooltip"
        :title="content.map[16].text"
        :class="{ active: current === 16 }"
        class="st1"
        cx="180.9"
        cy="345.5"
        r="25.3"
      />
    </NuxtLink>

    <!-- 17. Nordtrack™ S4.12 -->
    <NuxtLink
      :to="
        content.map[17].link.cached_url.indexOf('/') === 0
          ? content.map[17].link.cached_url
          : `/${content.map[17].link.cached_url}`
      "
    >
      <circle
        data-bs-toggle="tooltip"
        :title="content.map[17].text"
        :class="{ active: current === 17 }"
        class="st1"
        cx="123.6"
        cy="327.3"
        r="25.6"
      />
    </NuxtLink>

    <!-- 18. Conveyors -->
    <NuxtLink
      :to="
        content.map[18].link.cached_url.indexOf('/') === 0
          ? content.map[18].link.cached_url
          : `/${content.map[18].link.cached_url}`
      "
    >
      <circle
        data-bs-toggle="tooltip"
        :title="content.map[18].text"
        :class="{ active: current === 18 }"
        class="st1"
        cx="105.4"
        cy="66.1"
        r="12.2"
      />
    </NuxtLink>

    <!-- 19. Entrance/Balcony -->
    <NuxtLink
      :to="
        content.map[19].link.cached_url.indexOf('/') === 0
          ? content.map[19].link.cached_url
          : `/${content.map[19].link.cached_url}`
      "
    >
      <rect
        data-bs-toggle="tooltip"
        :title="content.map[19].text"
        :class="{ active: current === 19 }"
        x="46.8"
        y="195"
        class="st0"
        width="10.8"
        height="11.5"
      />
    </NuxtLink>

    <!-- 20. 3D Demo Stage -->
    <NuxtLink
      :to="
        content.map[20].link.cached_url.indexOf('/') === 0
          ? content.map[20].link.cached_url
          : `/${content.map[20].link.cached_url}`
      "
    >
      <circle
        data-bs-toggle="tooltip"
        :title="content.map[20].text"
        :class="{ active: current === 20 }"
        class="st1"
        cx="153.7"
        cy="173.7"
        r="15.5"
      />
    </NuxtLink>

    <!-- 21. Nordtrack 2.5 3D -->
    <NuxtLink
      :to="
        content.map[21].link.cached_url.indexOf('/') === 0
          ? content.map[21].link.cached_url
          : `/${content.map[21].link.cached_url}`
      "
    >
      <circle
        data-bs-toggle="tooltip"
        :title="content.map[21].text"
        :class="{ active: current === 21 }"
        class="st1"
        cx="206.3"
        cy="173.7"
        r="15.5"
      />
    </NuxtLink>

    <!-- 22. Nordtrack™ I908S 3D -->
    <NuxtLink
      :to="
        content.map[22].link.cached_url.indexOf('/') === 0
          ? content.map[22].link.cached_url
          : `/${content.map[22].link.cached_url}`
      "
    >
      <circle
        data-bs-toggle="tooltip"
        :title="content.map[22].text"
        :class="{ active: current === 22 }"
        class="st1"
        cx="153.7"
        cy="225.6"
        r="15.5"
      />
    </NuxtLink>

    <!-- 23. Nordtrack™ S4.9 3D -->
    <NuxtLink
      data-bs-toggle="tooltip"
      :title="content.map[23].text"
      :to="
        content.map[23].link.cached_url.indexOf('/') === 0
          ? content.map[23].link.cached_url
          : `/${content.map[23].link.cached_url}`
      "
    >
      <circle
        :class="{ active: current === 23 }"
        class="st1"
        cx="206.3"
        cy="225.6"
        r="15.5"
      />
    </NuxtLink>
  </svg>
</template>

<script setup>
const { $bootstrap } = useNuxtApp();
defineProps({
  content: {
    type: Object,
    required: true,
  },
  current: {
    type: Number,
    required: true,
  },
});
const map = useMap();
const toggleMap = () => {
  map.value = !map.value;
};

onMounted(() => {
  const tooltipTriggerList = document.querySelectorAll(
    '[data-bs-toggle="tooltip"]'
  );
  const tooltipList = [...tooltipTriggerList].map(
    (tooltipTriggerEl) =>
      new $bootstrap.Tooltip(tooltipTriggerEl, {
        boundary: document.body,
        trigger: "hover focus",
        container: ".map-modal-container",
      })
  );
});
</script>

<style scoped>
.st0 {
  fill: none;
  stroke: #b3b3b3;
  stroke-width: 1.44;
}
.st1 {
  fill: #e6e6e6;
  stroke: #b3b3b3;
  stroke-width: 1.44;
}

circle:focus,
rect:focus,
circle:hover {
  fill: white;
  stroke: #00e2b2;
}

rect:hover {
  fill: white;
  stroke: #00e2b2;
}

.active {
  fill: #00e2b2;
  stroke: #00e2b2;
}
</style>
